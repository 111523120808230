.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  background-color: rgba(0,0,0,.7);
  color: #FFF;
  z-index: 1;
  p {
    margin: 5px 0 0 !important;
    font-size: .5em;
    font-weight: 500;}
}