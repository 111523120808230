.spinner {
  animation: spin infinite 1s steps(8);
}

.table-striped tbody {
  tr:nth-of-type(odd){
    background-color: transparent;
  }
  > tr:nth-of-type(odd){
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.table-hover tbody {
  tr:hover {
    background-color: transparent;
  }
  > tr:hover {
    background-color: #f6f9fc;
  }
}

.date-item {
  padding: 1rem;
  // background: rgba(0, 0, 0, 0.05);
  &:not(:first-child){
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-width: 3px 0 0;
  }
  &:nth-child(2n+1){
    background: rgba(0, 0, 0, 0.02);
  }
  &:nth-child(2n+2){
    background: rgba(0, 0, 0, 0.05);
  }
}

.lecturer-item, .organizer-item {
  padding: 1rem;
  &:nth-child(2n+2){
    background: rgba(0, 0, 0, 0.02);
  }
  &:not(:first-child){
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-width: 3px 0 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
